<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="listas" class="p-datatable-sm" v-model:selection="selected" dataKey="id_subclasificacion" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['subclasificacion']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} subclasificaciones" :loading="loadingGrid">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Subclasificaciones de Productos</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="subclasificacion" header="Subclasificación" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Subclasificación</span>
							{{slotProps.data.subclasificacion}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="edit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				
				<Dialog v-model:visible="editDialog" :style="{width: '450px'}" header="Subclasificación" :modal="true">
					<div class="p-field">
						<div class="p-inputgroup p-m-1">
							<InputText id="subclasificacion" v-model="lista.subclasificacion" required="true" autofocus :class="{'p-invalid': submitted && !lista.subclasificacion}" />
							<small class="p-invalid" v-if="submitted && !lista.subclasificacion">Este información es requerida</small>
						</div>
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog=false"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarRec" />
					</template>
				</Dialog>

				<Dialog v-model:visible="enUsoDialog" :style="{width: '450px'}" header="En Uso" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="lista">No es posible remover la subclasificación <b>[{{ lista.subclasificacion }}]</b> por que tiene registros asociados!</span>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="enUsoDialog = false"/>						
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="lista">Esta seguro que desea eliminar la subclasificación seleccionada? <br><b>[{{ lista.subclasificacion }}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="eliminar" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import InvService from '../service/inv.service';
import {FilterMatchMode} from 'primevue/api';
// import Checkbox from 'primevue/checkbox';

export default {
	components:
	{
		Loading
	},
	data() {
		return {
			listas: null,
			listaDialog: false,		
			editingCellRows: {},
			editDialog: false,			
			deleteDialog: false,
			enUsoDialog: false,
			lista: null,
			selected: null,
			loading: false,
			loadingGrid: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false
		}
	},
	mounted() {
		this.loading = true;

		InvService.getSubclasificaciones(this.$store.state.auth.currentAppCtx).then(data => {
			this.listas = data;
			this.loading = false;
		}).catch(err => {
			this.loading = false;
			this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
			}
		);		
	},
	methods: {
		formatCurrency(value) {
			if(value)
				return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
			return;
		},
		formatPct(value) {
			if(value)
				return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
			return;
		},
        isPositiveNumeric(val) {
			if(this.isNumeric(val)){
				let num = parseFloat(val);
				return num >= 0.00;
			} else {
				return false;
			}
		},	
		isNumeric(n) {
			return !isNaN(parseFloat(n)) && isFinite(n);
		},				
		guardarRec(){
			this.editDialog = false;	
			this.loading = true;
			InvService.registrarInvSubclasificacion(this.$store.state.auth.currentAppCtx, this.lista).then(data => {				
				
				if(this.lista.id_subclasificacion == '') {
					this.lista.id_subclasificacion = data.id_subclasificacion;
					this.listas.push(this.lista);
				} 

				this.lista = {};
				this.loading = false;
			}).catch(data => {
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				this.submitted = true;
				this.editDialog = false;
				this.loading = false;
			})			
		},
		openNew() {
			this.lista = {
				id_subclasificacion: "",
				id_empresa: this.$store.state.auth.currentAppCtx.id_referencia,
				subclasificacion: "",
				activa: "S"		
			};
			this.submitted = false;
			this.editDialog = true;
		},
		edit(registro) {
			this.lista = registro;
			this.submitted = false;
			this.editDialog = true;
		},
		confirmDelete(registro) {
			this.loading = true;		
			InvService.InvSubclasificacionEnUso(this.$store.state.auth.currentAppCtx, registro).then((data) => {
				this.loading = false;
				this.lista = registro;
				if (!data.en_uso) {
					this.deleteDialog = true;					
				} else {
					this.enUsoDialog = true;
				}									
			}
			).catch(
				data => {
					this.deleteDialog = false;
					this.lista = {};
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)		
		},
		eliminar() {
			this.loading = true;
			InvService.eliminarInvSubclasificacion(this.$store.state.auth.currentAppCtx, this.lista).then(() => {
					this.listas = this.listas.filter(val => val.id_subclasificacion !== this.lista.id_subclasificacion);
					this.deleteDialog = false;
					this.lista = {};
					this.loading = false;
					this.$toast.add({severity:'success', summary: 'Exito', detail: 'La subclasificación ha sido eliminada', life: 3000})
			}
			).catch(
				data => {
					this.deleteDialog = false;
					this.lista = {};
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteDialog = true;
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
